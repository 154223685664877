.root {
  @apply flex flex-col items-center w-full h-full relative bg-white overflow-hidden;
}

.scoreDisplay {
  @apply absolute top-3 left-3 z-10 text-lg font-bold text-primary rounded-md;
  @apply bg-white bg-opacity-70 px-2 py-3;
}

.gameContainer {
  @apply w-full h-full relative flex-1 flex;
  height: calc(100% - 40px);
}

.gameCanvas {
  @apply w-full h-full block;
  touch-action: none;
}

.messageContainer {
  @apply absolute inset-0 flex items-center justify-center pointer-events-none z-10;
}

.message {
  @apply bg-white bg-opacity-80 p-4 rounded-lg shadow text-primary text-center;
  max-width: 80%;
}