.resultContainer {
  @apply relative w-full;
}

.textArea {
  @apply w-full h-64 bg-white border-none text-sm p-1;
  font-family: "JetBrains Mono", "Cascadia Code", ui-monospace, monospace !important;
}


.infoText {
  @apply italic text-gray;
}

.downloadContainer {
  @apply flex justify-end mt-2;
}
