.notification {
  @apply relative py-2 pr-4 pl-2 rounded border border-solid duration-300 ease-in-out mb-2;
}

.icon {
  @apply w-6 h-6 mr-2 flex-shrink-0;
}

.expanded {
  @apply py-4;

}

.content {
  @apply flex-grow min-w-0;
}

.header {
  @apply flex justify-between items-start;
}

.title {
  @apply mb-1;
}

.timestamp {
  @apply text-xs text-gray;
}

.details {
  @apply mt-2 space-y-2;
}

.expandIcon {
  @apply ml-2 flex-shrink-0;
}

.clickable {
  @apply cursor-pointer hover:bg-opacity-80 transition-all duration-200;
}

.nonExpandable {
  @apply cursor-pointer hover:bg-opacity-80 transition-all duration-200;
}

.nonExpandableHeader {
  @apply justify-between;
}

.info {
  @apply bg-primary-tint2 border-primary-lighter;
  
  .icon {
    @apply text-primary-lighter;
  }
}

.success {
  @apply bg-feedback-success-tint border-feedback-success;
  
  .icon {
    @apply text-feedback-success;
  }
}

.warning {
  @apply bg-feedback-warning-tint border-feedback-warning;
  
  .icon {
    @apply text-feedback-warning;
  }
}

.error {
  @apply bg-feedback-error-lighter border-feedback-error;
  
  .icon {
    @apply text-feedback-error;
  }
}

.preContent {
  @apply bg-gray-lighter p-2 rounded text-xs mt-1 max-h-40 overflow-auto;
}

.detailsContainer {
  @apply mt-2;
}

.detailLabel {
  @apply font-bold text-xs;
}

.scriptResultContainer {
  @apply mt-3;
}

.read {
  @apply bg-opacity-20;
}
