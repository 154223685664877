.pagination {
  @apply bg-white p-3 flex items-center justify-between text-gray-light border-t border-disabled border-solid border-l-0 border-r-0 border-b-0;
}

@media (max-width: 350px) {
  .detailedRange {
    @apply hidden;
  }
}

.pageButtons {
  @apply flex items-center;

  & button {
    @apply cursor-pointer flex items-center justify-center;
  }

  .pageIndicator {
    @apply mx-6 flex space-x-6 mobile:mx-2 mobile:space-x-2;
  }

  .pageButton {
    @apply text-base font-bold cursor-pointer;

    &.active {
      @apply text-primary-lighter;
    }
  }
}

.toolbarPanel {
  @apply p-3 w-full h-full bg-white;

  .actionButtons {
    @apply flex flex-col space-y-2 justify-center items-center mt-2;

    button {
      @apply w-full;
    }
  }

  .columns {
    @apply mt-4;
  }
}

:global(.ag-checkbox) :global(.ag-wrapper.ag-checkbox-input-wrapper) {
  @apply relative rounded-sm border border-solid border-muted bg-white outline-none;

  &::after {
    content: "";
  }

  &:global(.ag-indeterminate) {
    @apply border-primary-darker;

    &::after {
      @apply top-0.5 left-0.5 bottom-0.5 right-0.5 bg-primary-darker rounded-sm;
    }
  }

  &:global(.ag-checked) {
    @apply bg-primary-darker border-primary-darker;

    &::after {
      background-image: url("../../static/icons/gridCheckboxMark.svg");
      @apply top-0 left-0 bottom-0 right-0 bg-contain;
    }
  }

  input {
    @apply absolute cursor-pointer -top-3.5 -left-4 h-10 w-11;
  }
}

:global(.ag-invisible) {
  display: none !important;
}

// FIXME: Hope this doesn't lead to issues...
:global(.ag-cell-wrapper) {
  @apply h-full;
}

:global(#root) {
  .hideIndent0 {
    :global(.ag-row-level-1 .ag-cell:not(.ag-cell-first-right-pinned):first-of-type) {
      @apply pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-0) {
      @apply pl-0;

      > :global(.ag-group-value) {
        @apply pl-0;
      }

      > :global(.ag-group-expanded),
      > :global(.ag-group-contracted) {
        @apply hidden;
      }
    }
  }

  .hideIndent1 {
    :global(.ag-row-level-1 .ag-cell:not(.ag-cell-first-right-pinned):first-of-type) {
      @apply pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-0) {
      @apply ml-0 pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-1) {
      @apply ml-14 pl-0;

      > :global(.ag-group-value) {
        @apply pl-0;
      }

      > :global(.ag-group-expanded),
      > :global(.ag-group-contracted) {
        @apply hidden;
      }
    }
  }

  .hideIndent2 {
    :global(.ag-row-level-1 .ag-cell:not(.ag-cell-first-right-pinned):first-of-type) {
      @apply pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-0) {
      @apply ml-0 pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-1) {
      @apply ml-6 pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-2) {
      @apply ml-14 pl-0;

      > :global(.ag-group-value) {
        @apply pl-0;
      }

      > :global(.ag-group-expanded),
      > :global(.ag-group-contracted) {
        @apply hidden;
      }
    }
  }

  .hideIndent3 {
    :global(.ag-row-level-1 .ag-cell:not(.ag-cell-first-right-pinned):first-of-type) {
      @apply pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-0) {
      @apply ml-0 pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-1) {
      @apply ml-6 pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-2) {
      @apply ml-6 pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-3) {
      @apply ml-18 pl-0;

      > :global(.ag-group-value) {
        @apply pl-0;
      }

      > :global(.ag-group-expanded),
      > :global(.ag-group-contracted) {
        @apply hidden;
      }
    }
  }

  .hideIndent4 {
    :global(.ag-row-level-1 .ag-cell:not(.ag-cell-first-right-pinned):first-of-type) {
      @apply pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-0) {
      @apply ml-0 pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-1) {
      @apply ml-6 pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-2) {
      @apply ml-6 pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-3) {
      @apply ml-10 pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-4) {
      @apply ml-22 pl-0;

      > :global(.ag-group-value) {
        @apply pl-0;
      }

      > :global(.ag-group-expanded),
      > :global(.ag-group-contracted) {
        @apply hidden;
      }
    }
  }

  .hideIndent5 {
    :global(.ag-row-level-1 .ag-cell:not(.ag-cell-first-right-pinned):first-of-type) {
      @apply pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-0) {
      @apply ml-0 pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-1) {
      @apply ml-6 pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-2) {
      @apply ml-6 pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-3) {
      @apply ml-10 pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-4) {
      @apply ml-14 pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-5) {
      @apply ml-24 pl-0;

      > :global(.ag-group-value) {
        @apply pl-2;
      }

      > :global(.ag-group-expanded),
      > :global(.ag-group-contracted) {
        @apply hidden;
      }
    }
  }

  .hideIndent6 {
    :global(.ag-row-level-1 .ag-cell:not(.ag-cell-first-right-pinned):first-of-type) {
      @apply pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-0) {
      @apply ml-0 pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-1) {
      @apply ml-6 pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-2) {
      @apply ml-6 pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-3) {
      @apply ml-10 pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-4) {
      @apply ml-14 pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-5) {
      @apply ml-18 pl-0;
    }

    :global(.ag-row .ag-cell .ag-row-group-indent-6) {
      @apply ml-28 pl-0;

      > :global(.ag-group-value) {
        @apply pl-2;
      }

      > :global(.ag-group-expanded),
      > :global(.ag-group-contracted) {
        @apply hidden;
      }
    }
  }
}

.hideSortIcon {
  & :global(.ag-sort-indicator-container) {
    @apply hidden;
  }
}